<template>
  <div>
      <EditRoleForm></EditRoleForm>
  </div>
</template>

<script>
import EditRoleForm from '../../../components/Dashboard/Roles/EditRoleForm'
export default {
  components: { EditRoleForm },

}
</script>