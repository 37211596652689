<template>
  <div>
    <Wrapper>
      <div class="mb-10 text-left">
        <h2 class="text-xl"><b>Información del nuevo rol</b></h2>
        <p class="text-gray-500">
          Ingrese la información del rol y los módulos asociados
        </p>
      </div>
      <form
        @submit.prevent="onUpdatedRole"
        class="mx-auto max-w-2xl"
        v-if="!loading2"
      >
        <div class="w-full grid grid-cols-1 lg:grid-cols-2 gap-2">
          <BaseInput
            type="text"
            label="Nombre del rol"
            placeholder="Ej: Funcionario"
            v-model="form.name"
          />
          <BaseMultiSelect
            :defaultValues="void_array"
            title="Activación de módulos"
            :data="allModules"
            label="name"
            @notify-objects="setMultiSelect($event)"
          />
        </div>
        <div class="w-full grid grid-cols-1 lg:grid-cols-1 gap-2">
          <BaseTextarea
            ref="describe"
            id="describe"
            label="Descripción del rol"
            placeholder="Mensaje"
            v-model="form.description"
            maxlength="1000"
          />
        </div>
        <div v-if="feedback">
          <p class="my-2 text-xs text-center text-red-500">{{ feedback }}</p>
        </div>

        <div
          class="
            w-full
            lg:max-w-sm
            mt-8
            ml-auto
            flex flex-col
            lg:flex-row lg:justify-end
            gap-5
          "
        >
          <a-button
            type="danger"
            @click="$router.go(-1)"
            ghost
            shape="round"
            :block="true"
            :loading="loading"
          >
            <span class="mx-2">CANCELAR</span>
          </a-button>
          <a-button
            type="primary"
            html-type="submit"
            shape="round"
            :block="true"
            :loading="loading"
          >
            <span class="mx-2">GUARDAR</span>
          </a-button>
        </div>
      </form>
      <div v-if="loading2" class="flex justify-center items-center mt-32">
        <a-spin>
          <a-icon
            slot="indicator"
            type="loading"
            style="font-size: 64px"
            spin
          />
        </a-spin>
      </div>
    </Wrapper>
  </div>
</template>


<script>
import Wrapper from "../../UI/Wrapper";
export default {
  components: { Wrapper },
  data() {
    return {
      feedback: "",
      loading: false,
      loading2: false,
      allModules: [],
      void_array: [],
      idRole: null,
      form: {
        name: "",
        modules: [],
        description: "",
      },
    };
  },
  created() {
    this.loadModules();
    const { id } = this.$route.query;
    this.idRole = id;
    if (this.idRole) {
      this.getRoleData(this.idRole);
    } else {
      this.$router.go(-1);
    }
  },

  methods: {
    async loadModules() {
      this.loading2 = true;
      const { error, data } = await this.$api.getAllModules();

      if (error) this.allModules.push("Error al cargar los módulos.");
      else if (data) this.allModules = data.results;
    },
    async getRoleData(id) {
      this.loading2 = true;
      let { error, data } = await this.$api.getRole(id);
      if (error) {
        this.showToast("Ocurrió un error al cargar los datos de la dirección.");
      } else if (data) {
        this.form.name = data.name;
        this.form.description = data.detail_group.description;
        this.form.modules = data.modules;
        this.void_array = this.form.modules;
      }
      this.loading2 = false;
    },

    async onUpdatedRole() {
      if (!this.checkForm()) return;
      let roleForm = {
        name: "",
        modules: [],
        description: "",
      };
      roleForm.name = this.form.name;
      roleForm.description = this.form.description;
      roleForm.modules = this.toAsignParent();
      this.loading = true;
      let { data, error } = await this.$api.updateRole(roleForm, this.idRole);
      this.loading = false;
      if (error) {
        this.showToast("error", "Error al crear el rol.");
      } else if (data) {
        this.loading = false;
        if (data.code === 401) {
          this.showToast("error", data.error);
        } else {
          this.$store.dispatch("form/loadListOptions",{
              endpoint: 'group/',
              name: 'optionsGroupCompany'
            });
          this.$store.dispatch("session/loadUser");
          this.showToast("success", "Rol actualizado correctamente.");
          this.$router.go(-1);
        }
      }
    },
    toAsignParent() {
      if (this.form.modules.length === this.allModules.length)
        return [...this.form.modules];
      let completeModules = [...this.form.modules];
      this.allModules.forEach((element) => {
        if (this.form.modules.includes(element.id)) {
          if (element.id.parent_id) completeModules.push(element.parent_id);
        }
      });
      return completeModules;
    },
    checkForm() {
      this.feedback = null;

      if (this.$validator.isEmpty(this.form.name)) {
        this.feedback = "Es necesario un nombre para el rol";
        return false;
      }
      if (this.form.modules.length === 0) {
        this.feedback = "Debe seleccionar por lo menos un módulo";
        return false;
      }
      return true;
    },
    setMultiSelect(ev) {
      this.form.modules = ev.map((item) => item.id);
    },

    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
  },
};
</script>


<style scoped>
.top-0 {
  margin-top: -1.1em;
}
</style>